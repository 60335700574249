body {
  margin: 0;
  padding: 0;
  background-color: var(--color-background-default);
  color: var(--color-text-default);
  font-family: var(--font-family-body);
  font-size: var(--text-body-m);
  /* stylelint-disable */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* stylelint-enable */
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

a {
  text-decoration: none;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
/* stylelint-disable */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}
/* stylelint-enable */
