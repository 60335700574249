@font-face {
font-family: '__mdSystem_90b656';
src: url(/_next/static/media/013f174925bf55ba-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__mdSystem_90b656';
src: url(/_next/static/media/ebb061fb493da33c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
}@font-face {font-family: '__mdSystem_Fallback_90b656';src: local("Arial");ascent-override: 92.20%;descent-override: 32.11%;line-gap-override: 0.00%;size-adjust: 96.53%
}.__className_90b656 {font-family: '__mdSystem_90b656', '__mdSystem_Fallback_90b656', system-ui, sans-serif
}

@font-face {
font-family: '__replica_234272';
src: url(/_next/static/media/bbbf7e91185167b3-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__replica_Fallback_234272';src: local("Arial");ascent-override: 96.13%;descent-override: 25.30%;line-gap-override: 0.00%;size-adjust: 98.82%
}.__className_234272 {font-family: '__replica_234272', '__replica_Fallback_234272', system-ui, sans-serif
}

@font-face {
font-family: '__replicaMono_dfde6f';
src: url(/_next/static/media/b7b0c8bbca2e755c-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__replicaMono_Fallback_dfde6f';src: local("Arial");ascent-override: 71.06%;descent-override: 18.70%;line-gap-override: 0.00%;size-adjust: 133.68%
}.__className_dfde6f {font-family: '__replicaMono_dfde6f', '__replicaMono_Fallback_dfde6f', monospace
}

/* Document
 * ========================================================================== */

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Backgrounds do not repeat by default (opinionated).
 */

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  background-repeat: no-repeat; /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */

::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Breaks words to prevent overflow in all browsers (opinionated).
 * 4. Use a 4-space tab width in all browsers (opinionated).
 * 5. Remove the grey highlight on links in iOS (opinionated).
 * 6. Prevent adjustments of font size after orientation changes in iOS.
 */

:where(:root) {
  cursor: default; /* 1 */
  line-height: 1.5; /* 2 */
  overflow-wrap: break-word; /* 3 */
  -moz-tab-size: 4; /* 4 */
  tab-size: 4; /* 4 */
  -webkit-tap-highlight-color: transparent; /* 5 */
  -webkit-text-size-adjust: 100%; /* 6 */
}

/* Sections
 * ========================================================================== */

/**
 * Remove the margin in all browsers (opinionated).
 */

:where(body) {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

:where(h1) {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, and Safari.
 */

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
}

/**
 * 1. Correct the inheritance of border color in Firefox.
 * 2. Add the correct box sizing in Firefox.
 */

:where(hr) {
  color: inherit; /* 1 */
  height: 0; /* 2 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */

:where(nav) :where(ol, ul) {
  list-style-type: none;
  padding: 0;
}

/**
 * Prevent VoiceOver from ignoring list semantics in Safari (opinionated).
 */

:where(nav li)::before {
  content: "\200B";
  float: left;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Prevent overflow of the container in all browsers (opinionated).
 */

:where(pre) {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
  overflow: auto; /* 3 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Safari.
 */

:where(abbr[title]) {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

:where(b, strong) {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

:where(code, kbd, samp) {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

:where(small) {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/*
 * Change the alignment on media elements in all browsers (opinionated).
 */

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */

:where(iframe) {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */

:where(svg:not([fill])) {
  fill: currentColor;
}

/* Tabular data
 * ========================================================================== */

/**
 * 1. Collapse border spacing in all browsers (opinionated).
 * 2. Correct table border color inheritance in all Chrome, Edge, and Safari.
 * 3. Remove text indentation from table contents in Chrome, Edge, and Safari.
 */

:where(table) {
  border-collapse: collapse; /* 1 */
  border-color: inherit; /* 2 */
  text-indent: 0; /* 3 */
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

:where(button, input, select) {
  margin: 0;
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

:where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
  -webkit-appearance: button;
}

/**
 * Change the inconsistent appearance in all browsers (opinionated).
 */

:where(fieldset) {
  border: 1px solid #a0a0a0;
}

/**
 * Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

:where(progress) {
  vertical-align: baseline;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 3. Change the resize direction in all browsers (opinionated).
 */

:where(textarea) {
  margin: 0; /* 1 */
  resize: vertical; /* 3 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

:where([type="search" i]) {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct styles in Safari.
 */

:where(dialog) {
  background-color: white;
  border: solid;
  color: black;
  height: -moz-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: fit-content;
}

:where(dialog:not([open])) {
  display: none;
}

/*
 * Add the correct display in Safari.
 */

:where(details > summary:first-of-type) {
  display: list-item;
}

/* Accessibility
 * ========================================================================== */

/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */

:where([aria-busy="true" i]) {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */

:where([aria-controls]) {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */

:where([aria-disabled="true" i], [disabled]) {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */

:where([aria-hidden="false" i][hidden]) {
  display: initial;
}

:where([aria-hidden="false" i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

:root {
  /* General Color set up */
  --base-color-white: #fff;
  --base-color-blue-100: #e6f0ff;
  --base-color-blue-200: #c2d6f9;
  --base-color-blue-500: #2a6fe1;
  --base-color-blue-600: #1f4f9e;
  --base-color-grey-50: #fafafb;
  --base-color-grey-100: #f1f3f6;
  --base-color-grey-200: #ebeff1;
  --base-color-grey-300: #e6eaec;
  --base-color-grey-400: #b4c0c6;
  --base-color-grey-500: #8497a2;
  --base-color-grey-600: #5f7886;
  --base-color-grey-900: #092f45;
  --base-color-grey-1000: #031c2b;
  --base-color-purple-100: #f1eaff;
  --base-color-purple-200: #e4d8ff;
  --base-color-purple-500: #915eff;
  --base-color-purple-600: #7b4bdf;

  /* Named colors (according to their use) */
  --color-background-default: var(--base-color-white);
  --color-background-light: var(--base-color-grey-100);
  --color-background-faint: var(--base-color-grey-50);
  --color-background-inverted: var(--base-color-grey-1000);
  --color-background-card: var(--base-color-white);
  --color-background-alert: #ffeff3;
  --color-text-default: var(--base-color-grey-900);
  --color-text-secondary: var(--base-color-grey-600);
  --color-text-tertiary: var(--base-color-grey-500);
  --color-text-inverted-default: var(--base-color-white);
  --color-accent-default: var(--base-color-blue-500);
  --color-accent-hover: var(--base-color-blue-600);
  --color-accent-subdued: var(--base-color-blue-100);
  --color-text-on-color: var(--base-color-white);
  --color-text-alert: #b83b54;
  --color-border: rgb(9 47 69 / 10%);
  --color-border-subdued: rgb(9 47 69 / 5%);
  --color-border-accent: var(--base-color-blue-200);
  --color-overlay: rgb(9 47 69 / 85%);
  --color-input-border: var(--base-color-grey-300);
  --color-border-alert: #fddfe7;
  --color-input-border-alert: #d6919f;
  --color-table-border: var(--color-border);
  --color-table-stripe: var(--color-background-light);
  --color-teal: #85dabb;
  --color-teal-hover: #76ccad;
  --color-salmon: #eca283;
  --color-salmon-hover: #de9070;
  --color-yellow: #ffd482;
  --color-yellow-hover: #eec26f;
  --color-purple: var(--base-color-purple-500);
  --color-purple-hover: var(--base-color-purple-600);
  --color-alert: #ff0c46;
  --color-success: #00c48c;

  /* Data Viz Colors */
  --visualization-color-primary: var(--base-color-blue-500);
  --visualization-color-secondary: var(--base-color-grey-400);

  /* Text Sizes */
  --text-body-2xs: clamp(8.5px, 7.9194px + 0.1613vw, 10.5px);
  --text-body-xs: clamp(10px, 9.4194px + 0.1613vw, 12px);
  --text-body-s: clamp(12px, 11.4194px + 0.1613vw, 14px);
  --text-body-m: clamp(14px, 13.4194px + 0.1613vw, 16px);
  --text-body-l: clamp(16px, 15.4194px + 0.1613vw, 18px);
  --text-body-xl: clamp(18px, 16.8387px + 0.3226vw, 22px);
  --text-headline-xs: clamp(16px, 14.8387px + 0.3226vw, 20px);
  --text-headline-s: clamp(18px, 16.2581px + 0.4839vw, 24px);
  --text-headline-m: clamp(20px, 16.5161px + 0.9677vw, 32px);
  --text-headline-l: clamp(26px, 21.9355px + 1.129vw, 40px);
  --text-headline-xl: clamp(32px, 25.6129px + 1.7742vw, 54px);
  --text-headline-2xl: clamp(38px, 25.8065px + 3.3871vw, 80px);
  --text-headline-3xl: clamp(52px, 41.5484px + 2.9032vw, 88px);

  /* Font Families
   * These are moved inside src/pages/_app.jsx using next/font
  --font-family-body: 'MD System', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-headline: 'Replica', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-mono: 'Replica Mono', 'SF Mono', 'Roboto Mono', 'Fira Code', 'Fira Mono', 'Droid Sans Mono', 'Source Code Pro', monospace;
  */

  /* Spacing */
  --space-3xs: clamp(3px, 2.129px + 0.2419vw, 6px);
  --space-2xs: clamp(6px, 5.4194px + 0.1613vw, 8px);
  --space-xs: clamp(8px, 6.8387px + 0.3226vw, 12px);
  --space-s: clamp(12px, 10.8387px + 0.3226vw, 16px);
  --space-m: clamp(16px, 13.6774px + 0.6452vw, 24px);
  --space-l: clamp(24px, 21.6774px + 0.6452vw, 32px);
  --space-xl: clamp(32px, 27.3548px + 1.2903vw, 48px);
  --space-2xl: clamp(48px, 43.3548px + 1.2903vw, 64px);
  --space-3xl: clamp(64px, 54.7097px + 2.5806vw, 96px);
  --space-4xl: clamp(96px, 89.6129px + 1.7742vw, 118px);

  /* Spacing Pairs */
  --space-2xs-xs: clamp(6px, 4.2581px + 0.4839vw, 12px);
  --space-xs-s: clamp(8px, 5.6774px + 0.6452vw, 16px);
  --space-s-m: clamp(12px, 8.5161px + 0.9677vw, 24px);
  --space-m-l: clamp(16px, 11.3548px + 1.2903vw, 32px);
  --space-l-xl: clamp(24px, 17.0323px + 1.9355vw, 48px);
  --space-xl-2xl: clamp(32px, 22.7097px + 2.5806vw, 64px);
  --space-2xl-3xl: clamp(48px, 34.0645px + 3.871vw, 96px);
  --space-3xl-4xl: clamp(64px, 45.4194px + 5.1613vw, 128px);

  /* Define more spacing pairs here */

  /* Named Spaces */
  --row-gutter: var(--space-m);
  --column-gutter: var(--space-m);
  --layout-gutter: clamp(20px, 7.2258px + 3.5484vw, 64px);
  --card-spacing: var(--space-l);
  --banner-height: 0px;
  --nav-height: 70px;
  --blockquote-padding: 48px;

  /* Border Radius */
  --border-radius-small: 6px;
  --border-radius-default: 12px;
  --border-radius-large: 16px;

  /* Transition */
  --transition-default: 0.3s ease-in-out;
  --transition-slow: 0.5s ease-in-out;
  --transition-fast: 0.2s ease-in-out;

  /* Layout variables */
  --layout-max-width: 1920px;
  --grid-columns: 2;
  --layout-sticky-top-offset: 0px;
  --z-index-overlay: 999;
  --z-index-modal: 1000;
  --z-index-preview-banner: 3;
  --scrollbar-size: 10px;
  --blog-hero-overlap: 56px;
  --long-form-article-width: 680px;
}

@media (min-width: 700px) {
  :root {
    --grid-columns: 12;
    --card-spacing: var(--space-m);
  }
}

@media (min-width: 960px) {
  :root {
    --card-spacing: var(--space-m-l);
  }
}

@media (min-width: 1200px) {
  :root {
    --nav-height: 80px;
  }
}

@media (min-width: 1920px) {
  :root {
    --layout-gutter: calc((100vw - 1800px) / 2);
    --text-body-xs: 13px;
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--color-background-default);
  color: var(--color-text-default);
  font-family: var(--font-family-body);
  font-size: var(--text-body-m);
  /* stylelint-disable */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* stylelint-enable */
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

a {
  text-decoration: none;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
/* stylelint-disable */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}
/* stylelint-enable */

.ArrowLink_root__GrVmG {
  display: inline-flex;
  align-items: center;
  padding: 0;
  border: 0;
  background: transparent;
  color: inherit;
  font-family: var(--font-family-headline);
  font-size: var(--text-body-m);
  text-decoration: none;
  appearance: none;
  gap: 0.25em;
}

.ArrowLink_root__GrVmG.ArrowLink_primary__1_gEJ {
  color: var(--color-accent-default);
}

.ArrowLink_root__GrVmG:hover .ArrowLink_text__TJ6PQ {
  text-decoration: underline;
}

.PreviewBanner_root__lr9af {
  --notch-size: 5px;

  position: fixed;
  top: var(--space-2xl-3xl);
  left: 50%;
  z-index: var(--z-index-preview-banner);
  padding: var(--space-3xs) var(--space-2xs) var(--space-3xs) var(--space-xs);
  transform: translateX(-50%);
  background: var(--color-background-default);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-s);

  clip-path: polygon(
    0% var(--notch-size),
    var(--notch-size) 0%,
    calc(100% - var(--notch-size)) 0%,
    100% var(--notch-size),
    100% calc(100% - var(--notch-size)),
    calc(100% - var(--notch-size)) 100%,
    var(--notch-size) 100%,
    0% calc(100% - var(--notch-size))
  );
}

.PreviewBanner_paragraph__jNJQP {
  display: flex;
  gap: var(--space-s);
}

