:root {
  /* General Color set up */
  --base-color-white: #fff;
  --base-color-blue-100: #e6f0ff;
  --base-color-blue-200: #c2d6f9;
  --base-color-blue-500: #2a6fe1;
  --base-color-blue-600: #1f4f9e;
  --base-color-grey-50: #fafafb;
  --base-color-grey-100: #f1f3f6;
  --base-color-grey-200: #ebeff1;
  --base-color-grey-300: #e6eaec;
  --base-color-grey-400: #b4c0c6;
  --base-color-grey-500: #8497a2;
  --base-color-grey-600: #5f7886;
  --base-color-grey-900: #092f45;
  --base-color-grey-1000: #031c2b;
  --base-color-purple-100: #f1eaff;
  --base-color-purple-200: #e4d8ff;
  --base-color-purple-500: #915eff;
  --base-color-purple-600: #7b4bdf;

  /* Named colors (according to their use) */
  --color-background-default: var(--base-color-white);
  --color-background-light: var(--base-color-grey-100);
  --color-background-faint: var(--base-color-grey-50);
  --color-background-inverted: var(--base-color-grey-1000);
  --color-background-card: var(--base-color-white);
  --color-background-alert: #ffeff3;
  --color-text-default: var(--base-color-grey-900);
  --color-text-secondary: var(--base-color-grey-600);
  --color-text-tertiary: var(--base-color-grey-500);
  --color-text-inverted-default: var(--base-color-white);
  --color-accent-default: var(--base-color-blue-500);
  --color-accent-hover: var(--base-color-blue-600);
  --color-accent-subdued: var(--base-color-blue-100);
  --color-text-on-color: var(--base-color-white);
  --color-text-alert: #b83b54;
  --color-border: rgb(9 47 69 / 10%);
  --color-border-subdued: rgb(9 47 69 / 5%);
  --color-border-accent: var(--base-color-blue-200);
  --color-overlay: rgb(9 47 69 / 85%);
  --color-input-border: var(--base-color-grey-300);
  --color-border-alert: #fddfe7;
  --color-input-border-alert: #d6919f;
  --color-table-border: var(--color-border);
  --color-table-stripe: var(--color-background-light);
  --color-teal: #85dabb;
  --color-teal-hover: #76ccad;
  --color-salmon: #eca283;
  --color-salmon-hover: #de9070;
  --color-yellow: #ffd482;
  --color-yellow-hover: #eec26f;
  --color-purple: var(--base-color-purple-500);
  --color-purple-hover: var(--base-color-purple-600);
  --color-alert: #ff0c46;
  --color-success: #00c48c;

  /* Data Viz Colors */
  --visualization-color-primary: var(--base-color-blue-500);
  --visualization-color-secondary: var(--base-color-grey-400);

  /* Text Sizes */
  --text-body-2xs: fluid(8.5px, 10.5px);
  --text-body-xs: fluid(10px, 12px);
  --text-body-s: fluid(12px, 14px);
  --text-body-m: fluid(14px, 16px);
  --text-body-l: fluid(16px, 18px);
  --text-body-xl: fluid(18px, 22px);
  --text-headline-xs: fluid(16px, 20px);
  --text-headline-s: fluid(18px, 24px);
  --text-headline-m: fluid(20px, 32px);
  --text-headline-l: fluid(26px, 40px);
  --text-headline-xl: fluid(32px, 54px);
  --text-headline-2xl: fluid(38px, 80px);
  --text-headline-3xl: fluid(52px, 88px);

  /* Font Families
   * These are moved inside src/pages/_app.jsx using next/font
  --font-family-body: 'MD System', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-headline: 'Replica', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-mono: 'Replica Mono', 'SF Mono', 'Roboto Mono', 'Fira Code', 'Fira Mono', 'Droid Sans Mono', 'Source Code Pro', monospace;
  */

  /* Spacing */
  --space-3xs: fluid(3px, 6px);
  --space-2xs: fluid(6px, 8px);
  --space-xs: fluid(8px, 12px);
  --space-s: fluid(12px, 16px);
  --space-m: fluid(16px, 24px);
  --space-l: fluid(24px, 32px);
  --space-xl: fluid(32px, 48px);
  --space-2xl: fluid(48px, 64px);
  --space-3xl: fluid(64px, 96px);
  --space-4xl: fluid(96px, 118px);

  /* Spacing Pairs */
  --space-2xs-xs: fluid(6px, 12px);
  --space-xs-s: fluid(8px, 16px);
  --space-s-m: fluid(12px, 24px);
  --space-m-l: fluid(16px, 32px);
  --space-l-xl: fluid(24px, 48px);
  --space-xl-2xl: fluid(32px, 64px);
  --space-2xl-3xl: fluid(48px, 96px);
  --space-3xl-4xl: fluid(64px, 128px);

  /* Define more spacing pairs here */

  /* Named Spaces */
  --row-gutter: var(--space-m);
  --column-gutter: var(--space-m);
  --layout-gutter: fluid(20px, 64px);
  --card-spacing: var(--space-l);
  --banner-height: 0px;
  --nav-height: 70px;
  --blockquote-padding: 48px;

  /* Border Radius */
  --border-radius-small: 6px;
  --border-radius-default: 12px;
  --border-radius-large: 16px;

  /* Transition */
  --transition-default: 0.3s ease-in-out;
  --transition-slow: 0.5s ease-in-out;
  --transition-fast: 0.2s ease-in-out;

  /* Layout variables */
  --layout-max-width: 1920px;
  --grid-columns: 2;
  --layout-sticky-top-offset: 0px;
  --z-index-overlay: 999;
  --z-index-modal: 1000;
  --z-index-preview-banner: 3;
  --scrollbar-size: 10px;
  --blog-hero-overlap: 56px;
  --long-form-article-width: 680px;
}

@media (--small) {
  :root {
    --grid-columns: 12;
    --card-spacing: var(--space-m);
  }
}

@media (--medium) {
  :root {
    --card-spacing: var(--space-m-l);
  }
}

@media (--large) {
  :root {
    --nav-height: 80px;
  }
}

@media (--max) {
  :root {
    --layout-gutter: calc((100vw - 1800px) / 2);
    --text-body-xs: 13px;
  }
}
